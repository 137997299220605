import React, {useRef} from 'react'
import {func, node, string} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import uploadMedia from '../../services/awsS3UploaderService'
import {BENTLEY_CSV} from '../../constants/awsFolders'
import {localise} from '../../services/LocalizationServices'
import {Button} from 'react-bootstrap'

const propTypes = {
  children: node,
  className: string,
  onSuccess: func,
  variant: string,
}
const defaultProps = {
  children: null,
  className: '',
  onSuccess: () => Console.dev('on success'),
  variant: '',
}

const CsvUploader = ({children, className, onSuccess, variant}) => {
  const csvUpload = useRef()

  const handleUpload = e => {
    const reader = new FileReader()
    const file = e.target.files[0]
    if (e.target.files[0]) reader.readAsDataURL(e.target.files[0])

    reader.onloadend = () => {
      toggleLoading()
      uploadMedia(file, BENTLEY_CSV)
        .then(onSuccess)
        .finally(toggleLoading)
    }
  }

  return (
    <>
      <input ref={csvUpload} id='csv-upload' type='file' accept='.csv' hidden onChange={handleUpload} />
      <Button
        variant={variant || 'outline'}
        className={`font-13 ${className}`}
        onClick={() => csvUpload?.current?.click()}>
        {children || localise('buttonText.bulkUpload')}
      </Button>
    </>
  )
}

CsvUploader.propTypes = propTypes
CsvUploader.defaultProps = defaultProps

export default CsvUploader