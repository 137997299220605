import React, {useEffect, useState} from 'react'
import DealershipsTable from '../../components/tables/DealershipsTable'
import {localise} from '../../services/LocalizationServices'
import {fetchDealerships} from '../../api/dealership/dealership'
import {LIMIT} from '../../constants/api'
import {calculateOffset} from '../../components/pagination/Pagination'
import {useHistory} from 'react-router-dom'
import {DEALERSHIPS_URL} from '../../constants/urls'
import ListView from '../common/ListView'

const Dealerships = () => {
  const {push} = useHistory()

  const [dealerships, setDealerships] = useState([])
  const [total, setTotal] = useState()
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchDealerships({offset, query})
      .then(({dealerships, total}) => {
        setTotal(total)
        setDealerships(dealerships)
      })
      .finally(() => setLoading(false))
  }, [offset, query])

  const toDealership = id => push(`${DEALERSHIPS_URL}/${id}`)

  return (
    <ListView heading={localise('headings.retailers')}>
      <DealershipsTable
        data={dealerships}
        handleSearch={setQuery}
        onRowClick={toDealership}
        count={total}
        loading={loading}
        pagination={{
          onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
          total,
          offset,
        }}
      />
    </ListView>
  )
}

export default Dealerships
