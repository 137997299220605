import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import Dealerships from '../views/dealership/DealerShips'
import {BULK_UPLOAD_URL, EDIT_URL} from '../constants/urls'
import Dealership from '../views/dealership/Dealership'
import DealershipEdit from '../views/dealership/DealershipEdit'
import BulkUpload from '../views/dealership/BulkUpload'

const DealershipRoutes = ({path}) => {
  return (
    <Switch>
      <RouteWithLayout exact path={path} component={Dealerships} />
      <RouteWithLayout exact path={`${path}${BULK_UPLOAD_URL}/:slug`} component={BulkUpload} />
      <RouteWithLayout exact path={`${path}/:slug`} component={Dealership} />
      <RouteWithLayout exact path={`${path}/:slug${EDIT_URL}`} component={DealershipEdit} />
    </Switch>
  )
}

export default DealershipRoutes