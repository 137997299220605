import React, {useEffect, useState} from 'react'
import CustomersTable from '../../../components/tables/CustomersTable'
import {useParams} from 'react-router-dom'
import {fetchDealershipCustomers} from '../../../api/dealership/dealership'
import {calculateOffset} from '../../../components/pagination/Pagination'
import {LIMIT} from '../../../constants/api'

const Customers = () => {
  const {slug} = useParams()

  const [customers, setCustomers] = useState([])
  const [total, setTotal] = useState()
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchDealershipCustomers(slug,{offset, query})
      .then(({customers, total}) => {
        setTotal(total)
        setCustomers(customers)
      })
      .finally(() => setLoading(false))
  }, [slug, offset, query])

  return (
    <CustomersTable
      hideDealership
      count={total}
      data={customers}
      clickable={false}
      handleSearch={setQuery}
      loading={loading}
      pagination={{
        onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
        total,
        offset,
      }}
    />
  )
}

export default Customers