import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {setDealership} from '../../store/slices/dealershipSlice'
import {fetchDealership, updateDealership} from '../../api/dealership/dealership'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import DealershipForm from './DealershipForm'
import {DEALERSHIPS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {toast} from 'react-hot-toast'
import Prompt from '../../components/modals/Prompt'

const DealershipEdit = () => {
  const {slug} = useParams()
  const dispatch = useDispatch()
  const {goBack, push} = useHistory()

  const [dirty, setDirty] = useState(false)
  const [name, setName] = useState(null)

  useEffect(() => {
    toggleLoading()
    fetchDealership(slug)
      .then(dealership => {
        setName(dealership?.name)
        dispatch(setDealership(dealership))
      })
      .finally(toggleLoading)
  }, [dispatch, slug])

  const update = values => {
    const payload = {
      name: values?.name,
      trading_name: values?.tradingName,
      terms_of_sale: values?.termsOfSale,
      email: values?.email,
      homepage_url: values?.dealerHomepageUrl,
      registration_number: values?.registrationNumber,
      phone_number: values?.phoneNumber,
      address: {
        line1: values?.line1,
        line2: values?.line2,
        city: values?.city,
        postal_code: values?.postcode,
        country_code: values?.country,
      },
    }

    toggleLoading()
    updateDealership(slug, payload)
      .then(() => {
        setDirty(false)
        toast.success(localise('toast.retailerUpdateSuccess'))
        push(`${DEALERSHIPS_URL}/${slug}`)
      })
      .finally(toggleLoading)
  }

  return (
    <>
      <Prompt when={dirty} />
      <DealershipForm
        breadcrumbs={[
          {
            active: false,
            href: DEALERSHIPS_URL,
            text: localise('nav.retailers'),
          },
          {
            active: false,
            href: `${DEALERSHIPS_URL}/${slug}`,
            text: name,
          },
          {
            active: true,
            text: localise('nav.edit'),
          },
        ]}
        onSubmit={update}
        onCancel={() => goBack()}
        isDirty={setDirty}
        hideContactFields
      />
    </>
  )
}

export default DealershipEdit
