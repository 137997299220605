import React from 'react'
import {object, string} from 'prop-types'
import Highcharts from 'highcharts'
import 'chartkick/highcharts'
import {ColumnChart as ChartkickColumnChart} from 'react-chartkick'
import {BRAND_PRIMARY, GREY, PRIMARY_GREEN} from '../../constants/colours'

const propTypes = {
  chartProps: object,
  className: string,
  heading: string,
}

const defaultProps = {
  chartProps: {},
  className: '',
  heading: '',
}

Highcharts.setOptions({colors: [BRAND_PRIMARY, PRIMARY_GREEN]})

const ColumnChart = ({className, chartProps, data, heading}) =>
  <div className={className}>
    <div className='font-bold text-capitalize font-17'>
      {heading}
    </div>
    <ChartkickColumnChart
      data={data}
      library={{
        legend: {
          align: 'left',
          itemStyle: {
            color: GREY,
            fontFamily: 'Bentley',
            fontSize: 13,
            fontWeight: 'normal',
          },
          symbolPadding: 7,
          symbolHeight: 10,
          symbolWidth: 10,
        },
        plotOptions: {
          column: {
            crisp: true,
            grouping: true,
          },
        },
        tooltip: {
          borderWidth: 0,
          headerFormat: '<span>{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color}">{series.name}: </td><td><b>{point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          padding: 13,
          shadow: true,
          shared: true,
          useHTML: true,
        },
        xAxis: {crosshair: true},
        yAxis: {visible: false},
      }}
      thousands=','
      {...chartProps}
    />
  </div>

ColumnChart.propTypes = propTypes
ColumnChart.defaultProps = defaultProps

export default ColumnChart
