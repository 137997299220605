import React, {useEffect, useState} from 'react'
import {func, string} from 'prop-types'
import {Col, ProgressBar, Row} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import Table from '../../components/tables/Table'
import {useParams} from 'react-router-dom'

const propTypes = {
  heading: string,
  errorFetch: func.isRequired,
  importFetch: func.isRequired,
}

const defaultProps = {
  heading: '',
}

const INTERVAL_TIME = 500
const STATUS_COMPLETE = 'complete'

const BulkUploadView = ({heading, errorFetch, importFetch}) => {
  const {slug} = useParams()

  const [progress, setProgress] = useState(0)
  const [tableBody, setTableBody] = useState([])
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState()

  useEffect(() => {
    const interval = setInterval(() => {
      if (status !== STATUS_COMPLETE) {
        importFetch(slug)
          .then(({status, progress}) => {
            setProgress(progress * 100)
            setStatus(status)
            errorFetch(slug)
              .then(({errors, total}) => {
                setCount(total)
                setTableBody(errors?.map(({error_message, row}) => ({
                  row: [
                    {
                      cell: `${localise('tables.row')} ${row + 1}: ${error_message}`,
                    },
                  ],
                })))
              })
          })
          .catch(() => clearInterval(interval))
      }
    }, INTERVAL_TIME)
    return () => clearInterval(interval)
  }, [errorFetch, importFetch, slug, status])

  return (
    <Row className='csv-upload-container mb-5 mx-n4'>
      <Col xs='12' className='pb-4 px-4 box-shadow-bottom'>
        <div className='font-bold font-20 mb-2'>{heading || localise('headings.uploadingCsv')}</div>
        <ProgressBar className='font-bold' variant='primary' now={progress} label={`${progress}%`} />
      </Col>
      <Col xs='12' className='my-3 px-4'>
        <Table
          title={localise('headings.errors', {count})}
          count={count}
          data={{
            tableHeadings: [{text: localise('tables.errorMessage')}],
            tableBody,
          }}
          searchEnabled={false}
        />
      </Col>
    </Row>
  )
}

BulkUploadView.propTypes = propTypes
BulkUploadView.defaultProps = defaultProps

export default BulkUploadView