import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchOrderNumber = async ({period, dealership_id}) => {
  const {
    chart_data,
    total,
  } = await apiClient.get('statistics/orders/numbers', {params: {period, dealership_id}}).catch(handleError)

  return {total, chart_data}
}

export const fetchOrderValues = async ({period, dealership_id}) => {
  const {
    chart_data,
    total,
  } = await apiClient.get('statistics/orders/values', {params: {period, dealership_id}}).catch(handleError)

  return {total, chart_data}
}
