import React, {useEffect, useState} from 'react'
import ProductsTable from '../../../components/tables/ProductsTable'
import {useParams} from 'react-router-dom'
import {fetchDealershipProducts} from '../../../api/dealership/dealership'
import {calculateOffset} from '../../../components/pagination/Pagination'
import {LIMIT} from '../../../constants/api'

const Products = () => {
  const {slug} = useParams()

  const [products, setProducts] = useState([])
  const [total, setTotal] = useState()
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchDealershipProducts(slug,{offset, query})
      .then(({products, total}) => {
        setTotal(total)
        setProducts(products)
      })
      .finally(() => setLoading(false))
  }, [slug, offset, query])

  return (
    <ProductsTable
      count={total}
      data={products}
      clickable={false}
      handleSearch={setQuery}
      loading={loading}
      pagination={{
        onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
        total,
        offset,
      }}
    />
  )
}

export default Products
