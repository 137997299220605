import React from 'react'
import {localise} from '../../services/LocalizationServices'
import BulkUploadView from '../common/BulkUploadView'
import {fetchProductsUpload, fetchProductsUploadErrors} from '../../api/product/product'

const BulkUpload = () => {
  return (
    <BulkUploadView
      heading={localise('headings.uploadingProducts')}
      errorFetch={fetchProductsUploadErrors}
      importFetch={fetchProductsUpload}
    />
  )
}

export default BulkUpload