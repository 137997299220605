import React, {useEffect, useState} from 'react'
import {array, bool, func} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import FormView from '../common/FormView'
import Separator from '../../components/separator/Separator'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {useDispatch, useSelector} from 'react-redux'
import {
  getDealershipCity,
  getDealershipContactEmail,
  getDealershipContactFirstName,
  getDealershipContactLastName,
  getDealershipCountry, getDealershipCountryCode,
  getDealershipEmail,
  getDealershipHomepageUrl,
  getDealershipLine1,
  getDealershipLine2,
  getDealershipName,
  getDealershipPostcode,
  getDealershipTermsOfSale,
  getDealershipTradingName,
  getDealershipRegistrationNumber,
  getDealershipPhoneNumber,
} from '../../store/selectors/dealershipSelectors'
import {setDealership} from '../../store/slices/dealershipSlice'
import Console from '../../utilities/ConsoleUtil'
import {getCountries} from '../../store/selectors/configurationSelectors'
import {EMAIL_REGEX} from '../../constants/regex'

const propTypes = {
  breadcrumbs: array,
  hideContactFields: bool,
  isDirty: func,
  onBlur: func,
  onCancel: func,
  onSubmit: func,
}

const defaultProps = {
  breadcrumbs: [],
  hideContactFields: false,
  isDirty: dirty => Console.dev(dirty),
  onBlur: () => Console.dev('on blur'),
  onCancel: () => Console.dev('on cancel'),
  onSubmit: () => Console.dev('on submit'),
}

const COUNTRY_SELECT_ID = 'country-select'

const DealershipForm = ({breadcrumbs, hideContactFields, isDirty, onBlur, onCancel, onSubmit}) => {
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)
  const [emailValid, setEmailValid] = useState(true)

  const name = useSelector(getDealershipName) || ''
  const tradingName = useSelector(getDealershipTradingName) || ''
  const dealerHomepageUrl = useSelector(getDealershipHomepageUrl) || ''
  const email = useSelector(getDealershipEmail) || ''
  const termsOfSale = useSelector(getDealershipTermsOfSale) || ''
  const line1 = useSelector(getDealershipLine1)|| ''
  const line2 = useSelector(getDealershipLine2) || ''
  const city = useSelector(getDealershipCity) || ''
  const postcode = useSelector(getDealershipPostcode) || ''
  const country = useSelector(getDealershipCountry) || ''
  const countryCode = useSelector(getDealershipCountryCode) || ''
  const contactFirstName = useSelector(getDealershipContactFirstName) || ''
  const contactLastName = useSelector(getDealershipContactLastName) || ''
  const contactEmail = useSelector(getDealershipContactEmail) || ''
  const registrationNumber = useSelector(getDealershipRegistrationNumber) || ''
  const phoneNumber = useSelector(getDealershipPhoneNumber) || ''
  const countries = useSelector(getCountries)

  useEffect(() => {
    setEmailValid(true)
    const validationDelay = setTimeout(() => {
      setEmailValid(EMAIL_REGEX.test(String(email).toLowerCase()))
    }, 500)
    return () => clearTimeout(validationDelay)
  }, [email])

  const dealershipDetailsFields = [
    {
      type: 'text',
      name: 'name',
      label: localise('form.label.name'),
      validationMessage: localise('form.validation.name'),
      value: name,
      handler: name => dispatch(setDealership({name})),
      required: true,
    },
    {
      type: 'text',
      name: 'tradingName',
      label: localise('form.label.tradingName'),
      validationMessage: localise('form.validation.tradingName'),
      value: tradingName,
      handler: trading_name => dispatch(setDealership({trading_name})),
      required: true,
    },
    {
      type: 'text',
      name: 'dealerHomepageUrl',
      label: localise('form.label.retailerHomepageUrl'),
      placeholder: localise('form.placeholder.url'),
      validationMessage: localise('form.validation.retailerHomepageUrl'),
      value: dealerHomepageUrl,
      handler: homepage_url => dispatch(setDealership({homepage_url})),
      required: true,
    },
    {
      type: 'email',
      name: 'email',
      label: localise('form.label.email'),
      validationMessage: !emailValid
        ? localise('form.validation.validEmailRequired')
        : localise('form.validation.email'),
      value: email,
      handler: email => dispatch(setDealership({email})),
      required: true,
      isInvalid: email && !emailValid,
    },
    {
      type: 'text',
      name: 'phoneNumber',
      label: localise('form.label.phoneNumber'),
      validationMessage: localise('form.validation.phoneNumber'),
      value: phoneNumber,
      handler: phone_number => dispatch(setDealership({phone_number})),
      required: true,
    },
    {
      type: 'text',
      name: 'registrationNumber',
      label: localise('form.label.registrationNumber'),
      validationMessage: localise('form.validation.registrationNumber'),
      value: registrationNumber,
      handler: registration_number => dispatch(setDealership({registration_number})),
      required: true,
    },
    {
      type: 'textarea',
      name: 'termsOfSale',
      label: localise('form.label.termsOfSale'),
      validationMessage: localise('form.validation.termsOfSale'),
      value: termsOfSale,
      handler: terms_of_sale => dispatch(setDealership({terms_of_sale})),
      required: true,
    },
  ]

  const addressFields = [
    {
      type: 'text',
      name: 'line1',
      label: localise('form.label.addressLine'),
      validationMessage: localise('form.validation.addressLine'),
      value: line1,
      handler: line1 => dispatch(setDealership({line1})),
      required: true,
    },
    {
      type: 'text',
      name: 'line2',
      label: localise('form.label.townCity'),
      validationMessage: localise('form.validation.townCity'),
      value: line2,
      handler: line2 => dispatch(setDealership({line2})),
      required: true,
    },
    {
      type: 'text',
      name: 'city',
      label: localise('form.label.county'),
      validationMessage: localise('form.validation.county'),
      value: city,
      handler: city => dispatch(setDealership({city})),
      required: true,
    },
    {
      type: 'text',
      name: 'postcode',
      label: localise('form.label.postcode'),
      validationMessage: localise('form.validation.postcode'),
      value: postcode,
      handler: postcode => dispatch(setDealership({postcode})),
      required: true,
    },
    {
      type: 'dropdown',
      id: COUNTRY_SELECT_ID,
      classNamePrefix: COUNTRY_SELECT_ID,
      name: 'country',
      label: localise('form.label.country'),
      placeholder: localise('form.placeholder.country'),
      validationMessage: localise('form.validation.country'),
      options: countries,
      value: (countryCode && country ? {value: countryCode, label: country} : null),
      handler: ({value: country_code, label: country}) => dispatch(setDealership({country, country_code})),
      required: true,
      menuPortalTarget: document.body,
    },
  ]

  const contactFields = [
    {
      type: 'text',
      name: 'contactFirstName',
      label: localise('form.label.primaryContactFirstName'),
      validationMessage: localise('form.validation.primaryContactFirstName'),
      placeholder: localise('form.placeholder.name'),
      value: contactFirstName,
      handler: contact_first_name => dispatch(setDealership({contact_first_name})),
      required: true,
    },
    {
      type: 'text',
      name: 'contactLastName',
      label: localise('form.label.primaryContactLastName'),
      validationMessage: localise('form.validation.primaryContactLastName'),
      placeholder: localise('form.placeholder.name'),
      value: contactLastName,
      handler: contact_last_name => dispatch(setDealership({contact_last_name})),
      required: true,
    },
    {
      type: 'email',
      name: 'contactEmail',
      label: localise('form.label.primaryContactEmail'),
      validationMessage: localise('form.validation.primaryContactEmail'),
      placeholder: localise('form.placeholder.email'),
      value: contactEmail,
      handler: contact_email => dispatch(setDealership({contact_email})),
      required: true,
    },
  ]

  const handleDirtyFields = fields => isDirty(Object.keys(fields).length !== 0)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      onSubmit({
        name,
        tradingName,
        dealerHomepageUrl,
        email,
        termsOfSale,
        line1,
        line2,
        city,
        postcode,
        country: countryCode,
        contactFirstName,
        contactLastName,
        contactEmail,
        registrationNumber,
        phoneNumber,
      })
    }
    setValidated(true)
  }

  const submittable = !!name &&
                      !!tradingName &&
                      !!dealerHomepageUrl &&
                      !!email &&
                      !!phoneNumber &&
                      !!registrationNumber &&
                      !!termsOfSale &&
                      !!line1 &&
                      !!line2 &&
                      !!city &&
                      !!postcode &&
                      !!countryCode &&
                      (hideContactFields || (
                        !!contactFirstName &&
                        !!contactLastName &&
                        !!contactEmail
                      ))

  return (
    <FormView
      heading={localise('headings.retailerDetails')}
      breadcrumbs={breadcrumbs}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      onBlur={onBlur}
      validated={validated}
      submittable={submittable}
      onDirtyFieldsChanged={handleDirtyFields}>
      {buildForm(dealershipDetailsFields)}
      <Separator className='mx-n3 mb-2' />
      {buildForm(addressFields)}
      {
        !hideContactFields &&
       <>
         <Separator className='mx-n3 mb-2' />
         {buildForm(contactFields)}
       </>
      }
    </FormView>
  )
}

DealershipForm.propTypes = propTypes
DealershipForm.defaultProps = defaultProps

export default DealershipForm
