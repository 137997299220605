import React from 'react'
import {Tabs, Tab} from 'react-bootstrap'
import {array, func, string} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import styled from 'styled-components'
import {PRIMARY_GREEN} from '../../constants/colours'

const propTypes = {
  activeKey: string,
  className: string,
  data: array.isRequired,
  defaultActiveKey: string,
  onSelect: func,
}

const defaultProps = {
  activeKey: '',
  className: '',
  data: [
    {
      tabClassName: '',
      eventKey: '',
      title: '',
      content: '',
    },
    {
      tabClassName: '',
      eventKey: '',
      title: '',
      content: '',
    },
  ],
  defaultActiveKey: '',
  onSelect: label => Console.dev(`onSelect label => ${label}`),
}

const TabNavContainer = styled(Tabs)`
  border: 0;

  a.nav-item.nav-link {
    border: 0;
    font-family: Bentley, sans-serif;
    &.active {
      border-bottom: 3px solid ${PRIMARY_GREEN} !important;
      font-family: BentleyBold, sans-serif;
    }
  }
`

const TabMenu = ({activeKey, className, data, defaultActiveKey, onSelect}) =>
  <TabNavContainer
    activeKey={activeKey || defaultActiveKey || data[0]?.eventKey}
    className={`tab-nav-container font-14 font-bold ${className}`}
    onSelect={onSelect}>
    {
      data?.map(({eventKey, content, tabClassName, title}) =>
        <Tab tabClassName={`pl-0 mr-3 ${tabClassName}`} eventKey={eventKey} title={title} key={eventKey}>
          {content}
        </Tab>
      )
    }
  </TabNavContainer>

TabMenu.propTypes = propTypes
TabMenu.defaultProps = defaultProps

export default TabMenu
