import React from 'react'
import {PRODUCTS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'
import {
  getMasterProductName,
  getProductDealershipName,
} from '../../store/selectors/productSelectors'
import ProductView from '../common/ProductView'

const ProductVariant = () => {
  const {productSlug} = useParams()

  const productDealershipName = useSelector(getProductDealershipName)
  const masterProductName = useSelector(getMasterProductName)


  return (
    <ProductView
      breadcrumbs={[
        {
          active: false,
          href: PRODUCTS_URL,
          text: localise('nav.products'),
        },
        {
          active: false,
          href: `${PRODUCTS_URL}/${productSlug}`,
          text: masterProductName,
        },
        {
          active: true,
          text: productDealershipName,
        },
      ]}
    />
  )
}

export default ProductVariant
