import React, {useEffect, useState} from 'react'
import BreadCrumbsView from '../common/BreadcrumbsView'
import {CUSTOMERS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {
  fetchCustomer,
  updateCustomer,
  deleteCustomer,
} from '../../api/customer/customers'
import {useDispatch, useSelector} from 'react-redux'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {setCustomer} from '../../store/slices/customerSlice'
import {useParams} from 'react-router-dom'
import {
  getCustomerActive,
  getCustomerEmail,
  getCustomerFirstName,
  getCustomerLastName,
  getCustomerName,
  getCustomerPhoneNumber,
} from '../../store/selectors/customerSelectors'
import {Button, Col, Dropdown} from 'react-bootstrap'
import DropdownButton from '../../components/buttons/DropdownButton'
import DeleteModal from '../../components/modals/DeleteModal'
import {v4} from 'uuid'
import {toast} from 'react-hot-toast'
import {useHistory} from 'react-router'
import Card from '../../components/cards/Card'
import Paragraph from '../../components/paragraph/Paragraph'
import Separator from '../../components/separator/Separator'
import TabMenu from '../../components/navs/TabMenu'
import Orders from './details/Orders'
import Dealerships from './details/Dealerships'

const Customer = () => {
  const DEALERSHIPS = localise('headings.retailers')
  const ORDERS = localise('headings.orders')

  const views = {
    [DEALERSHIPS]: <Dealerships />,
    [ORDERS]: <Orders />,
  }

  const TAB_DATA = [
    {
      eventKey: localise('headings.retailers'),
      title: localise('headings.retailers'),
    },
    {
      eventKey: localise('headings.orders'),
      title: localise('headings.orders'),
    },
  ]

  const dispatch = useDispatch()
  const {push} = useHistory()
  const {slug} = useParams()

  const [show, setShow] = useState(false)
  const [view, setView] = useState(TAB_DATA[0].eventKey)

  const name = useSelector(getCustomerName)
  const active = useSelector(getCustomerActive)
  const firstName = useSelector(getCustomerFirstName)
  const lastName = useSelector(getCustomerLastName)
  const email = useSelector(getCustomerEmail)
  const phoneNumber = useSelector(getCustomerPhoneNumber)

  useEffect(() => {
    toggleLoading()
    fetchCustomer(slug)
      .then(customer => dispatch(setCustomer(customer)))
      .finally(toggleLoading)
  }, [dispatch, slug])

  const handleDelete = () => {
    setShow(false)
    toggleLoading()
    deleteCustomer(slug)
      .then(deleted => {
        if (deleted) {
          push(CUSTOMERS_URL)
          toast.success(localise('toast.customerDeleteSuccess'))
        }
      })
      .finally(toggleLoading)
  }

  const updateStatus = () => {
    toggleLoading()
    updateCustomer(slug, {active: !active})
      .then(customer => dispatch(setCustomer(customer)))
      .finally(toggleLoading)
  }

  return (
    <>
      <BreadCrumbsView
        breadcrumbItems={[
          {
            active: false,
            href: CUSTOMERS_URL,
            text: localise('nav.customers'),
          },
          {
            active: true,
            text: name,
          },
        ]}
        buttons={(
          <div className='d-flex mt-2 mt-md-0'>
            <Button
              variant='red'
              className='font-13 mr-2 ml-md-auto'
              onClick={() => setShow(true)}>
              {localise('buttonText.delete')}
            </Button>
            <DropdownButton
              toggleClasses='font-13'
              variant='outline'
              menuAlignment='right'
              menu={[
                <Dropdown.Item key={v4()} onClick={updateStatus}>
                  {localise(`buttonText.${!active ? 'active' : 'inactive'}`)}
                </Dropdown.Item>,
              ]}>
              {localise(`buttonText.${active ? 'active' : 'inactive'}`)}
            </DropdownButton>
          </div>
        )}
        className='m-2 m-sm-3'>
        <>
          <Card className='py-2 row mx-0 mb-4 shadow-none border'>
            <Col xs={12} className='font-bold font-15 mb-2'>
              {localise('headings.customerDetails')}
            </Col>
            <Col sm={6} className='border-bottom py-1'>
              <Paragraph heading={localise('headings.firstName')} children={firstName} />
            </Col>
            <Col sm={6} className='border-bottom py-1'>
              <Paragraph heading={localise('headings.lastName')} children={lastName} />
            </Col>
            <Col sm={6} className='py-1'>
              <Paragraph heading={localise('headings.email')} children={email} />
            </Col>
            <Separator className='w-100 px-0 mx-0 d-sm-none' />
            <Col sm={6} className='pt-2'>
              <Paragraph heading={localise('headings.phoneNumber')} children={phoneNumber} />
            </Col>
          </Card>
          <TabMenu
            activeKey={view}
            className='mb-2'
            data={TAB_DATA}
            onSelect={setView}
          />
          {views[view]}
        </>
      </BreadCrumbsView>
      <DeleteModal
        name={localise('modalText.customer')}
        onConfirm={handleDelete}
        hide={() => setShow(false)}
        show={show}
      />
    </>
  )
}

export default Customer
