import React, {useEffect} from 'react'
import {array} from 'prop-types'
import ProductCard from '../../components/cards/ProductCard'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {
  getProductDescription,
  getProductDescriptionOne,
  getProductDescriptionThree,
  getProductDescriptionTwo,
  getProductImages,
  getProductName,
  getProductNameOne,
  getProductNameThree,
  getProductNameTwo,
  getProductOverview,
  getProductVideos,
} from '../../store/selectors/productSelectors'
import {fetchDealershipProduct} from '../../api/product/product'
import BreadCrumbsView from '../common/BreadcrumbsView'
import {setProduct} from '../../store/slices/productSlice'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'

const propTypes = {
  breadcrumbs: array,
}

const defaultProps = {
  breadcrumbs: [],
}

const ProductView = ({breadcrumbs}) => {
  const {slug} = useParams()
  const dispatch = useDispatch()

  const name = useSelector(getProductName)
  const description = useSelector(getProductDescription)
  const overview = useSelector(getProductOverview)
  const images = useSelector(getProductImages) || []
  const videos = useSelector(getProductVideos) || []
  const productNameOne = useSelector(getProductNameOne)
  const productDescriptionOne = useSelector(getProductDescriptionOne)
  const productNameTwo = useSelector(getProductNameTwo)
  const productDescriptionTwo = useSelector(getProductDescriptionTwo)
  const productNameThree = useSelector(getProductNameThree)
  const productDescriptionThree = useSelector(getProductDescriptionThree)

  useEffect(() => {
    toggleLoading()
    fetchDealershipProduct(slug)
      .then(product => dispatch(setProduct(product)))
      .finally(toggleLoading)
  }, [dispatch, slug])

  const handleMedia = () => {
    const media = [...images.map(({url: src}) => ({src, type: 'image'}))]

    if (videos.length > 0) media.push({src: videos[0]?.url, type: 'video'})
    return media
  }

  return (
    <BreadCrumbsView
      hideButtons
      breadcrumbItems={breadcrumbs}>
      <ProductCard
        heading={name}
        subheading={description}
        media={handleMedia()}
        overview={overview}
        details={{
          headingOne: productNameOne,
          textOne: productDescriptionOne,
          headingTwo: productNameTwo,
          textTwo: productDescriptionTwo,
          headingThree: productNameThree,
          textThree: productDescriptionThree,
        }}
      />
    </BreadCrumbsView>
  )
}

ProductView.propTypes = propTypes
ProductView.defaultProps = defaultProps

export default ProductView
