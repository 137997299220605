export const getDealershipName = state => state?.dealership?.name
export const getDealershipFullAddress = state => state?.dealership?.address_string
export const getDealershipEmail = state => state?.dealership?.email
export const getDealershipHomepageUrl = state => state?.dealership?.homepage_url
export const getDealershipTermsOfSale = state => state?.dealership?.terms_of_sale
export const getDealershipTradingName = state => state?.dealership?.trading_name
export const getDealershipActive = state => state?.dealership?.active
export const getDealershipLine1 = state => state?.dealership?.line1
export const getDealershipLine2 = state => state?.dealership?.line2
export const getDealershipCity = state => state?.dealership?.city
export const getDealershipPostcode = state => state?.dealership?.postcode
export const getDealershipCountry = state => state?.dealership?.country
export const getDealershipCountryCode = state => state?.dealership?.country_code
export const getDealershipContactFirstName = state => state?.dealership?.contact_first_name
export const getDealershipContactLastName = state => state?.dealership?.contact_last_name
export const getDealershipContactEmail = state => state?.dealership?.contact_email
export const getDealershipRegistrationNumber = state => state?.dealership?.registration_number
export const getDealershipPhoneNumber = state => state?.dealership?.phone_number
