import enLocale from '../config/translations/en.translations.json'
import intl from 'react-intl-universal'
import {toggleLoading} from '../utilities/LoadingIndicatorUtil'
import {useDispatch} from 'react-redux'
import {fetchCurrentUser} from '../api/admin/admin'
import {setCurrentUser} from '../store/slices/currentUserSlice'
import Console from '../utilities/ConsoleUtil'
import {fetchConfiguration} from '../api/configuration/config'
import {setConfiguration} from '../store/slices/configurationSlice'

const LOCALES = {
  'en-US': enLocale,
  'en': enLocale,
  'en-UK': enLocale,
  'en-GB': enLocale,
}

const Init = () => {
  const dispatch = useDispatch()

  intl.init({
    currentLocale: Object.keys(LOCALES).includes(window.navigator.language) ? window.navigator.language : 'en',
    locales: LOCALES,
  });

  (async () => {
    try {
      toggleLoading()
      const configuration = await fetchConfiguration()
      if (configuration) dispatch(setConfiguration(configuration))
      const user = await fetchCurrentUser()
      if (user) dispatch(setCurrentUser(user))
    }
    catch (e) { Console.dev(e, 'error') }
    finally { toggleLoading() }
  })()

  return null
}

export default Init
