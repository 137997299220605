import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import OrdersTable from '../../components/tables/OrdersTable'
import {fetchOrders} from '../../api/order/order'
import {LIMIT} from '../../constants/api'
import {calculateOffset} from '../../components/pagination/Pagination'
import {useHistory} from 'react-router-dom'
import {ORDERS_URL} from '../../constants/urls'
import TabMenu from '../../components/navs/TabMenu'
import {localise} from '../../services/LocalizationServices'
import useOrders from '../../hooks/useOrders'

const Orders = () => {
  const {statuses: orderStatuses} = useOrders()
  const TAB_DATA = orderStatuses?.map(({param: eventKey, label: title}) => ({eventKey, title}))
  const {push} = useHistory()

  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [statuses, setStatuses] = useState(TAB_DATA[0]?.eventKey)
  const [total, setTotal] = useState()

  useEffect(() => {
    setLoading(true)
    fetchOrders({offset, query, statuses})
      .then(({orders, total}) => {
        setTotal(total)
        setOrders(orders?.map(order => ({
          ...order,
          orderCode: order.order_code,
          orderValue: order.order_value?.display,
          date: order.created_at,
          status: orderStatuses?.filter(({param}) => order?.status === param)[0]?.label,
        })))
      })
      .finally(() => setLoading(false))
  }, [offset, query, statuses])

  const toOrder = id => push(`${ORDERS_URL}/${id}`)

  return (
    <Row>
      <Col xs='12'>
        <div className='font-20 font-bold'>{localise('nav.orders')}</div>
        <TabMenu
          activeKey={statuses}
          className='mb-2'
          data={TAB_DATA}
          onSelect={setStatuses}
        />
        <Row>
          <Col>
            <OrdersTable
              data={orders}
              handleSearch={setQuery}
              onRowClick={toOrder}
              count={total}
              loading={loading}
              pagination={{
                onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
                total,
                offset,
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Orders
