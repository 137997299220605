export const AUTH_URL = '/auth'
export const BULK_UPLOAD_URL = '/bulk-upload'
export const CODE_URL = '/code'
export const CUSTOMERS_URL = '/customers'
export const DASHBOARD_URL = '/dashboard'
export const DEALERSHIPS_URL = '/dealerships'
export const DEALERSHIP_PRODUCT_URL = '/dealership-product'
export const EDIT_URL = '/edit'
export const NEW_URL = '/new'
export const ORDERS_URL = '/orders'
export const PRODUCTS_URL = '/products'
export const ROOT_URL = '/'
export const VARIANTS_URL = '/variants'
