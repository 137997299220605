class Console {
    static dev = message => {
      if (process.env.NODE_ENV === 'development') {
        console.log('%c---- dev ----', 'background: #fff; color: #000')
        console.log(message)
        console.log('%c-------------', 'background: #fff; color: #000')
      }
    }
}

export default Console