import React, {useEffect, useState} from 'react'
import OrdersTable from '../../../components/tables/OrdersTable'
import {fetchCustomerOrders} from '../../../api/customer/customers'
import {useParams} from 'react-router-dom'
import {ORDERS_URL} from '../../../constants/urls'
import {calculateOffset} from '../../../components/pagination/Pagination'
import {useHistory} from 'react-router'

const Orders = () => {
  const {push} = useHistory()
  const {slug} = useParams()

  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState()

  useEffect(() => {
    setLoading(true)
    fetchCustomerOrders(slug, {offset, query})
      .then(({orders, total}) => {
        orders.map(order => {
          order.orderCode = order?.order_code
          order.orderValue = order?.order_value?.display
          order.date = order?.created_at

          delete order.order_code
          delete order.order_value
          return order
        })
        setOrders(orders)
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }, [query, offset, slug])

  const toOrder = id => push(`${ORDERS_URL}/${id}`)

  return (
    <OrdersTable
      count={total}
      data={orders}
      hideName
      handleSearch={setQuery}
      loading={loading}
      onRowClick={toOrder}
      pagination={{
        onPageChange: page => setOffset(calculateOffset(page)),
        offset,
        total,
      }}
    />
  )
}

export default Orders
