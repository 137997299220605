import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

export const fetchProducts = async opts => {
  const params = getIndexParams(opts)

  const {products, meta: {total}} = await apiClient.get('products', {params}).catch(handleError)
  return {products, total}
}

export const deleteProduct = async id => {
  const {deleted} = await apiClient.delete(`products/${id}`).catch(handleError)
  return deleted
}

const productAction = async (action, url, data) => {
  const {product} = await apiClient[action](url, data).catch(handleError)
  return product
}

export const createProduct = product => productAction('post', 'products', {product})
export const updateProduct = (id, product) => productAction('put', `products/${id}`, {product})
export const fetchProduct = id => productAction('get', `products/${id}`)

export const fetchDealershipProducts = async (id, opts)  => {
  const params = getIndexParams(opts)

  const {dealership_products, meta: {total}} = await apiClient.get(`products/${id}/dealership_products`, {params})
  return {dealership_products, total}
}

export const fetchDealershipProduct = async id => {
  const {dealership_product} = await apiClient.get(`dealership_products/${id}`)
  return dealership_product
}

export const bulkUploadProducts = async url => {
  const {import: upload} = await apiClient.post('products/imports', {
    import: {
      csv_file_url: url,
    },
  }).catch(handleError)
  return upload
}

export const fetchProductsUpload = async id => {
  const {import: upload} = await apiClient.get(`products/imports/${id}`).catch(handleError)
  return upload
}

export const fetchProductsUploadErrors = async id => {
  const {errors, meta: {total}} = await apiClient.get(`products/imports/${id}/errors`).catch(handleError)
  return {errors, total}
}
