import {createSlice} from '@reduxjs/toolkit'

const dealershipSlice = createSlice({
  name: 'dealership',
  initialState: {},
  reducers: {
    setDealership: (state, action) => {

      if (action?.payload?.address?.line1) {
        action.payload.line1 = action?.payload?.address?.line1
      }

      if (action?.payload?.address?.line2) {
        action.payload.line2 = action?.payload?.address?.line2
      }

      if (action?.payload?.address?.city) {
        action.payload.city = action?.payload?.address?.city
      }

      if (action?.payload?.address?.country_code) {
        action.payload.country_code = action?.payload?.address?.country_code
      }

      if (action?.payload?.address?.country) {
        action.payload.country = action?.payload?.address?.country
      }

      if (action?.payload?.address?.postal_code) {
        action.payload.postcode = action?.payload?.address?.postal_code
      }

      if (action?.payload?.primary_contact?.first_name) {
        action.payload.contact_first_name = action?.payload?.primary_contact?.first_name
      }

      if (action?.payload?.primary_contact?.last_name) {
        action.payload.contact_last_name = action?.payload?.primary_contact?.last_name
      }

      if (action?.payload?.primary_contact?.email) {
        action.payload.contact_email = action?.payload?.primary_contact?.email
      }

      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearDealership: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const dealershipReducer = dealershipSlice.reducer
export const {setDealership, clearDealership} = dealershipSlice.actions
