import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {fetchDealership, updateDealership} from '../../api/dealership/dealership'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import BreadCrumbsView from '../common/BreadcrumbsView'
import StatCard from '../../components/cards/StatCard'
import {localise} from '../../services/LocalizationServices'
import {Button, Col, Dropdown, Row} from 'react-bootstrap'
import Paragraph from '../../components/paragraph/Paragraph'
import {DEALERSHIPS_URL, EDIT_URL} from '../../constants/urls'
import DropdownButton from '../../components/buttons/DropdownButton'
import {v4} from 'uuid'
import TabMenu from '../../components/navs/TabMenu'
import Products from './details/Products'
import Orders from './details/Orders'
import Customers from './details/Customers'
import {useDispatch, useSelector} from 'react-redux'
import {
  getDealershipActive,
  getDealershipEmail,
  getDealershipFullAddress,
  getDealershipHomepageUrl,
  getDealershipName,
  getDealershipTermsOfSale,
} from '../../store/selectors/dealershipSelectors'
import {setDealership} from '../../store/slices/dealershipSlice'
import {isLoading} from '../../store/selectors/loadingSelectors'
import Skeleton from 'react-loading-skeleton'

const CUSTOMERS = 'customers'
const ORDERS = 'orders'
const PRODUCTS = 'products'

const Dealership = () => {
  const {slug} = useParams()
  const {push} = useHistory()
  const dispatch = useDispatch()

  const [view, setView] = useState(null)

  const name = useSelector(getDealershipName)
  const active = useSelector(getDealershipActive)
  const address = useSelector(getDealershipFullAddress)
  const homepageUrl = useSelector(getDealershipHomepageUrl)
  const email = useSelector(getDealershipEmail)
  const termsOfSale = useSelector(getDealershipTermsOfSale)
  const loading = useSelector(isLoading)

  const views = {
    [CUSTOMERS]: <Customers />,
    [ORDERS]: <Orders />,
    [PRODUCTS]: <Products />,
  }

  useEffect(() => {
    toggleLoading()
    fetchDealership(slug)
      .then(dealership => {
        dispatch(setDealership(dealership))
        setView(PRODUCTS)
      })
      .finally(toggleLoading)
  }, [slug, dispatch])

  const toEdit = () => push(`${DEALERSHIPS_URL}/${slug}${EDIT_URL}`)

  const updateStatus = () => {
    toggleLoading()
    updateDealership(slug, {active: !active})
      .then(dealership => dispatch(setDealership(dealership)))
      .finally(toggleLoading)
  }

  return (
    <>
      <BreadCrumbsView
        breadcrumbItems={[
          {
            active: false,
            href: DEALERSHIPS_URL,
            text: localise('nav.retailers'),
          }, {
            active: true,
            text: name,
          },
        ]}
        buttons={(
          <div className='d-flex mt-2 mt-md-0'>
            <Button
              variant='secondary'
              className='font-13 mr-2 ml-md-auto'
              onClick={toEdit}>
              {localise('buttonText.edit')}
            </Button>
            <DropdownButton
              toggleClasses='font-13'
              variant='outline'
              menuAlignment='right'
              menu={[
                <Dropdown.Item key={v4()} onClick={updateStatus}>
                  {localise(`buttonText.${!active ? 'active' : 'inactive'}`)}
                </Dropdown.Item>,
              ]}>
              {localise(`buttonText.${active ? 'active' : 'inactive'}`)}
            </DropdownButton>
          </div>
        )}>
        <StatCard heading={localise('headings.retailerDetails')}>
          <Row>
            <Col sm='6' xs='12'>
              <Paragraph heading={localise('headings.name')}>{name}</Paragraph>
            </Col>
            <Col sm='6' xs='12'>
              <Paragraph heading={localise('headings.address')}>{address}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col sm='6' xs='12'>
              <Paragraph heading={localise('headings.retailerHomepageUrl')}>{homepageUrl}</Paragraph>
            </Col>
            <Col sm='6' xs='12'>
              <Paragraph heading={localise('headings.supportEmail')}>{email}</Paragraph>
            </Col>
          </Row>
          <Row>
            <Col xs='12'>
              <Paragraph heading={localise('headings.termsOfSale')}>{termsOfSale}</Paragraph>
            </Col>
          </Row>
        </StatCard>
        {
          loading
            ? <div className='mt-4'><Skeleton count={2} height={50} /></div>
            : (
              <>
                <TabMenu
                  activeKey={view}
                  className='my-3'
                  onSelect={setView}
                  data={[
                    {
                      eventKey: 'products',
                      title: localise('nav.products'),
                    },
                    {
                      eventKey: 'orders',
                      title: localise('nav.orders'),
                    },
                    {
                      eventKey: 'customers',
                      title: localise('nav.customers'),
                    },
                  ]}
                />
                {views[view]}
              </>
            )
        }
      </BreadCrumbsView>
    </>
  )
}

export default Dealership
