import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

export const fetchCustomers = async opts => {
  const params = getIndexParams(opts)

  const {customers, meta: {total}} = await apiClient.get('/customers', {params}).catch(handleError)
  return {customers, total}
}

export const fetchCustomerDealerships = async (id, opts) => {
  const params = getIndexParams(opts)

  const {
    dealerships,
    meta: {total},
  } = await apiClient.get(`/customers/${id}/dealerships`, {params}).catch(handleError)
  return {dealerships, total}
}

export const fetchCustomerOrders = async (id, opts) => {
  const params = getIndexParams(opts)

  const {orders, meta: {total}} = await apiClient.get(`/customers/${id}/orders`, {params}).catch(handleError)
  return {orders, total}
}

export const deleteCustomer = async id => {
  const {deleted} = await apiClient.delete(`customers/${id}`).catch(handleError)
  return deleted
}

const customerAction = async (action, url, data) => {
  const {customer} = await apiClient[action](url, data).catch(handleError)
  return customer
}

export const fetchCustomer = id => customerAction('get', `customers/${id}`)
export const updateCustomer = (id, customer) => customerAction('put', `customers/${id}`, {customer})

