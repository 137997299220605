import React, {useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {fetchOrder} from '../../api/order/order'
import {useParams} from 'react-router'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {ORDERS_URL} from '../../constants/urls'
import BreadCrumbsView from '../common/BreadcrumbsView'
import {setOrder} from '../../store/slices/orderSlice'
import {useDispatch, useSelector} from 'react-redux'
import CustomerDetails from './details/CustomerDetails'
import OrderDetails from './details/OrderDetails'
import {getOrderCode} from '../../store/selectors/orderSelectors'

const Order = () => {
  const {slug} = useParams()
  const dispatch = useDispatch()

  const code = useSelector(getOrderCode)

  useEffect(() => {
    toggleLoading()
    fetchOrder(slug)
      .then(order => dispatch(setOrder(order)))
      .finally(toggleLoading)
  }, [dispatch, slug])

  return (
    <BreadCrumbsView
      hideButtons
      breadcrumbItems={[
        {
          active: false,
          href: ORDERS_URL,
          text: localise('nav.orders'),
        },
        {
          active: true,
          text: code || slug,
        },
      ]}>
      <Row>
        <Col md='6' className='mb-3'>
          <OrderDetails />
        </Col>
        <Col md='6'>
          <CustomerDetails />
        </Col>
      </Row>
    </BreadCrumbsView>
  )
}

export default Order
