import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

export const fetchOrders = async opts => {
  const statuses = opts?.statuses || ''
  const params = getIndexParams(opts)

  const {orders, meta: {total}} = await apiClient.get('/orders', {params: {...params, statuses}}).catch(handleError)
  return {orders, total}
}

export const fetchOrder = async id => {
  const {order} = await apiClient.get(`orders/${id}`).catch(handleError)
  return order
}
