import React, {useEffect, useState} from 'react'
import DealershipsTable from '../../../components/tables/DealershipsTable'
import {fetchCustomerDealerships} from '../../../api/customer/customers'
import {useParams} from 'react-router-dom'
import {DEALERSHIPS_URL} from '../../../constants/urls'
import {calculateOffset} from '../../../components/pagination/Pagination'
import {useHistory} from 'react-router'

const Dealerships = () => {
  const {push} = useHistory()
  const {slug} = useParams()

  const [dealerships, setDealerships] = useState([])
  const [loading, setLoading] = useState(true)
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [total, setTotal] = useState()

  useEffect(() => {
    setLoading(true)
    fetchCustomerDealerships(slug, {offset, query})
      .then(({dealerships, total}) => {
        setDealerships(dealerships)
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }, [query, offset, slug])

  const toDealership = id => push(`${DEALERSHIPS_URL}/${id}`)

  return (
    <DealershipsTable
      count={total}
      data={dealerships}
      handleSearch={setQuery}
      loading={loading}
      onRowClick={toDealership}
      pagination={{
        onPageChange: page => setOffset(calculateOffset(page)),
        offset,
        total,
      }}
    />
  )
}

export default Dealerships
