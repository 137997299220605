import React from 'react'
import {Form} from 'react-bootstrap'
import Select from 'react-select'
import {v4} from 'uuid'
import ImageUploader from '../../components/inputs/ImageUploader'
import VideoUploader from '../../components/inputs/VideoUploader'

const DEFAULT_TEXTAREA_ROWS = 5

const defaultHandleChange = (e, setStateHandler) => setStateHandler(e?.target?.value)

export const buildForm = (FORM_DATA, handleChange = defaultHandleChange) => FORM_DATA.map((field, index) => {
  const handleInputTypes = () => {
    if (field.type === 'textarea') {
      return (
        <Form.Control
          as='textarea'
          required={field.required || false}
          readOnly={field.readOnly || false}
          type={field.type}
          isInvalid={field.isInvalid || false}
          rows={field.rows || DEFAULT_TEXTAREA_ROWS}
          placeholder={field.placeholder || field.label}
          onChange={e => handleChange(e, field.handler)}
          defaultValue={field.defaultValue}
          name={field.name}
          value={field.value}
          maxLength={field.maxLength}
        />
      )
    }
    else if (field.type === 'dropdown') {
      const {defaultInputValue, handler} = field
      if (field.handler) {
        return (
          <Select
            {...field}
            className={`${field.readOnly && 'readonly'} ${field.isInvalid && 'invalid'}`}
            isDisabled={field.disabled}
            isClearable={!field.readOnly}
            isSearchable={!field.readOnly}
            openMenuOnClick={!field.readOnly}
            onChange={handler}
            handler={0}
            key={defaultInputValue}
            defaultInputValue={defaultInputValue}
          />
        )
      }
      return (
        <Select
          {...field}
          className={`${field.readOnly && 'readonly'} ${field.isInvalid && 'invalid'}`}
          isDisabled={field.disabled}
          isClearable={!field.readOnly}
          isSearchable={!field.readOnly}
          openMenuOnClick={!field.readOnly}
          key={defaultInputValue}
          defaultInputValue={defaultInputValue}
        />
      )
    } else if (field.type === 'custom') {
      return field.render
    } else if (field.type === 'image-upload') {
      return (
        <ImageUploader
          className={field?.isInvalid ? 'border-danger' : ''}
          onChange={field.handler}
          onLoading={field.onLoading}
          {...field}
        />
      )
    } else if (field.type === 'video-upload') {
      return <VideoUploader onChange={field.handler} onLoading={field.onLoading} {...field} />
    }
    else {
      return (
        <Form.Control
          className={field.className || ''}
          defaultValue={field.defaultValue}
          isInvalid={field.isInvalid || false}
          key={field.defaultValue}
          onChange={e => handleChange(e, field.handler)}
          placeholder={field.placeholder || field.label}
          readOnly={field.readOnly || false}
          required={field.required || false}
          type={field.type}
          name={field.name}
          value={field.value}
        />
      )
    }
  }

  return (
    <Form.Group controlId={v4()} key={`${field.label}${index}`} className={field.additionalContainerClasses || ''}>
      <Form.Label
        hidden={!field.label}
        className={`text-left w-100 font-15 ${field.additionalLabelClasses || ''}`}>
        <span className='font-bold'>
          {
            field.required ? `${field.label}*` : field.label
          }
        </span>
        <span hidden={!field.subLabel} className='font-12 font-light-grey ml-2'>{field.subLabel}</span>
      </Form.Label>
      {handleInputTypes()}
      {
        (field.type === 'dropdown' || field.type === 'image-upload') && field.isInvalid
          ? <div className='d-block invalid-feedback'>{field.validationMessage}</div>
          : (
            <Form.Control.Feedback type='invalid'>
              {field.validationMessage}
            </Form.Control.Feedback>
          )
      }
    </Form.Group>
  )
})

export default buildForm
