import {
  CUSTOMERS_URL,
  DASHBOARD_URL,
  DEALERSHIPS_URL,
  ORDERS_URL,
  PRODUCTS_URL,
} from '../constants/urls'
import LogoutButton from '../components/buttons/LogoutButton'

const CUSTOMERS_LINK = {
  to: CUSTOMERS_URL,
  translationKey: 'nav.customers',
  activeViews: ['customers'],
}

const DASHBOARD_LINK = {
  to: DASHBOARD_URL,
  translationKey: 'nav.dashboard',
  activeViews: ['dashboard'],
}

const DEALERSHIPS_LINK = {
  to: DEALERSHIPS_URL,
  translationKey: 'nav.retailers',
  activeViews: ['dealerships'],
}

const ORDERS_LINK = {
  to: ORDERS_URL,
  translationKey: 'nav.orders',
  activeViews: ['orders'],
}

const PRODUCTS_LINK = {
  to: PRODUCTS_URL,
  translationKey: 'nav.products',
  activeViews: ['products'],
}

const useNav = () => {
  const links = {
    customers: CUSTOMERS_LINK,
    dashboard: DASHBOARD_LINK,
    dealerships: DEALERSHIPS_LINK,
    logout: {button: LogoutButton},
    orders: ORDERS_LINK,
    products: PRODUCTS_LINK,
  }

  const isActiveLink = views => {
    const pathname = window.location.href

    const parsedUrl = pathname.toLowerCase().replace(/\s/g, '')
    return views.some(view => parsedUrl.includes(view.toLowerCase().replace(/\s/g, '')))
  }

  return {
    collapseBreakpoint: 'lg',
    isActiveLink,
    links,
  }
}

export default useNav
