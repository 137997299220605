import React, {useEffect, useState} from 'react'
import {Button, Dropdown} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {useParams} from 'react-router-dom'
import {PRODUCTS_URL, EDIT_URL} from '../../constants/urls'
import DropdownButton from '../../components/buttons/DropdownButton'
import BreadCrumbsView from '../common/BreadcrumbsView'
import {useHistory} from 'react-router'
import {v4} from 'uuid'
import DeleteModal from '../../components/modals/DeleteModal'
import {deleteProduct, fetchProduct, updateProduct} from '../../api/product/product'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import ProductCard from '../../components/cards/ProductCard'
import {toast} from 'react-hot-toast'
import ProductVariants from './ProductVariants'
import {useDispatch, useSelector} from 'react-redux'
import {
  getProductActive,
  getProductDescription,
  getProductDescriptionOne,
  getProductDescriptionThree,
  getProductDescriptionTwo,
  getProductImages,
  getProductName,
  getProductNameOne,
  getProductNameThree,
  getProductNameTwo,
  getProductOverview,
  getProductVideos,
} from '../../store/selectors/productSelectors'
import {setProduct} from '../../store/slices/productSlice'

const Product = () => {
  const {slug} = useParams()
  const {push} = useHistory()
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  const name = useSelector(getProductName)
  const description = useSelector(getProductDescription)
  const overview = useSelector(getProductOverview)
  const images = useSelector(getProductImages) || []
  const videos = useSelector(getProductVideos) || []
  const productNameOne = useSelector(getProductNameOne)
  const productDescriptionOne = useSelector(getProductDescriptionOne)
  const productNameTwo = useSelector(getProductNameTwo)
  const productDescriptionTwo = useSelector(getProductDescriptionTwo)
  const productNameThree = useSelector(getProductNameThree)
  const productDescriptionThree = useSelector(getProductDescriptionThree)
  const active = useSelector(getProductActive)

  useEffect(() => {
    toggleLoading()
    fetchProduct(slug)
      .then(product => dispatch(setProduct(product)))
      .finally(toggleLoading)
  }, [dispatch, slug])

  const toEdit = () => push(`${PRODUCTS_URL}/${slug}${EDIT_URL}`)
  const toProducts = () => push(PRODUCTS_URL)

  const handleMedia = () => {
    const media = [...images.map(({url: src}) => ({src, type: 'image'}))]

    if (videos.length > 0) media.push({src: videos[0]?.url, type: 'video'})
    return media
  }

  const handleDelete = () => {
    toggleLoading()
    deleteProduct(slug)
      .then(() => {
        toast.success(localise('toast.productDeleteSuccess'))
        toProducts()
      })
      .finally(toggleLoading)
  }

  const updateStatus = () => {
    toggleLoading()
    updateProduct(slug, {active: !active})
      .then(product => dispatch(setProduct(product)))
      .finally(toggleLoading)
  }

  return (
    <>
      <BreadCrumbsView
        breadcrumbItems={[
          {
            active: false,
            href: PRODUCTS_URL,
            text: localise('nav.products'),
          }, {
            active: true,
            text: name,
          },
        ]}
        buttons={(
          <div className='d-flex mt-2 mt-md-0'>
            <Button
              className='font-13 mr-2 ml-md-auto'
              onClick={() => setShow(true)}
              variant='red'>
              {localise('buttonText.delete')}
            </Button>
            <Button
              variant='secondary'
              className='font-13 mr-2'
              onClick={toEdit}>
              {localise('buttonText.edit')}
            </Button>
            <DropdownButton
              toggleClasses='font-13'
              variant='outline'
              menuAlignment='right'
              menu={[
                <Dropdown.Item key={v4()} onClick={updateStatus}>
                  {localise(`buttonText.${!active ? 'active' : 'inactive'}`)}
                </Dropdown.Item>,
              ]}>
              {localise(`buttonText.${active ? 'active' : 'inactive'}`)}
            </DropdownButton>
          </div>
        )}>
        <ProductCard
          heading={name}
          subheading={description}
          media={handleMedia()}
          overview={overview}
          details={{
            headingOne: productNameOne,
            textOne: productDescriptionOne,
            headingTwo: productNameTwo,
            textTwo: productDescriptionTwo,
            headingThree: productNameThree,
            textThree: productDescriptionThree,
          }}
        />
        <ProductVariants />
      </BreadCrumbsView>
      <DeleteModal
        name={localise('modalText.product')}
        onConfirm={handleDelete}
        hide={() => setShow(false)}
        show={show}
      />
    </>
  )
}

export default Product
