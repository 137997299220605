import React from 'react'
import {array, bool, exact, func, number, string} from 'prop-types'
import Table from './Table'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import StatusBadge from '../badges/StatusBadge'

const propTypes = {
  className: string,
  clickable: bool,
  count: number,
  data: array.isRequired,
  handleSearch: func,
  hideProductViews: bool,
  loading: bool,
  onRowClick: func,
  pagination: exact({
    displayStatus: bool,
    className: string,
    onPageChange: func,
    pageCount: number,
    total: number,
    page: number,
    limit: number,
    offset: number,
  }),
}

const defaultProps = {
  clickable: true,
  className: '',
  count: null,
  data: [],
  handleSearch: () => Console.dev('search changed'),
  hideProductViews: false,
  loading: false,
  onRowClick: () => Console.dev('row clicked'),
  pagination: null,
}

const ProductsTable = props => {
  const {
    className,
    clickable,
    count,
    data,
    handleSearch,
    hideProductViews,
    loading,
    onRowClick,
    pagination,
  } = props

  const tableHeadings = [
    localise('tables.name'),
    localise('tables.shortDescription'),
    {text: localise('tables.productViews'), attributes: {className: hideProductViews && 'd-none'}},
    localise('tables.status'),
  ]

  const tableBody = data?.map(({id, name, description, views, active, dealership}) => ({
    rowAttributes: {
      onClick: () => clickable ? onRowClick(id, dealership) : null,
      className: id && clickable && 'clickable',
    },
    row: [
      {cell: name},
      {cell: description},
      {
        cell: views,
        attributes: {className: hideProductViews && 'd-none'},
      },
      {cell: <StatusBadge active={active} />},
    ],
  }))

  return (
    <Table
      title={localise('tables.products', {count})}
      className={`products-table ${className}`}
      data={{tableHeadings, tableBody}}
      count={count}
      pagination={pagination}
      skeletonProps={{count: data?.length}}
      loading={loading}
      handleSearch={handleSearch}
    />
  )
}

ProductsTable.propTypes = propTypes
ProductsTable.defaultProps = defaultProps

export default ProductsTable
