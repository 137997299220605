import React from 'react'
import {array, bool, exact, func, number, string} from 'prop-types'
import Table from './Table'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import StatusBadge from '../badges/StatusBadge'
import {DateTime} from 'luxon'
import {EUROPEAN_DATE_FORMAT} from '../../constants/dates'

const propTypes = {
  className: string,
  clickable: bool,
  count: number,
  data: array.isRequired,
  handleSearch: func,
  hideDealership: bool,
  hideName: bool,
  hideStatus: bool,
  loading: bool,
  onRowClick: func,
  pagination: exact({
    displayStatus: bool,
    className: string,
    onPageChange: func,
    pageCount: number,
    total: number,
    page: number,
    limit: number,
    offset: number,
  }),
}

const defaultProps = {
  className: '',
  clickable: true,
  count: null,
  data: [],
  handleSearch: () => Console.dev('search changed'),
  hideDealership: false,
  hideName: false,
  hideStatus: false,
  loading: false,
  onRowClick: () => Console.dev('row clicked'),
  pagination: null,
}

const OrdersTable = props => {
  const {
    className,
    clickable,
    count,
    data,
    handleSearch,
    hideDealership,
    hideName,
    hideStatus,
    loading,
    onRowClick,
    pagination,
  } = props

  const tableHeadings = [
    {text: localise('tables.name'), attributes: {className: hideName && 'd-none'}},
    localise('tables.product'),
    localise('tables.orderCode'),
    localise('tables.orderValue'),
    localise('tables.date'),
    {text: localise('tables.retailer'), attributes: {className: hideDealership && 'd-none'}},
    {text: localise('tables.status'), attributes: {className: hideStatus && 'd-none'}},
  ]

  const tableBody = data?.map(({id, name, product, orderCode, orderValue, date, dealership, status}) => ({
    rowAttributes: {
      onClick: () => clickable ? onRowClick(id) : null,
      className: id && clickable && 'clickable',
    },
    row: [
      {
        cell: name,
        attributes: {className: hideName && 'd-none'},
      },
      {cell: product},
      {cell: orderCode},
      {cell: orderValue},
      {cell: DateTime.fromSeconds(date || 0).toFormat(EUROPEAN_DATE_FORMAT)},
      {
        cell: dealership,
        attributes: {className: hideDealership && 'd-none'},
      },
      {
        cell: <StatusBadge active text={status} />,
        attributes: {className: hideStatus && 'd-none'},
      },
    ],
  }))

  return (
    <Table
      title={localise('tables.orders', {count})}
      className={`orders-table ${className}`}
      data={{tableHeadings, tableBody}}
      count={count}
      pagination={pagination}
      skeletonProps={{count: data?.length}}
      loading={loading}
      handleSearch={handleSearch}
    />
  )
}

OrdersTable.propTypes = propTypes
OrdersTable.defaultProps = defaultProps

export default OrdersTable
