import React from 'react'
import {fetchDealershipsUpload, fetchDealershipsUploadErrors} from '../../api/dealership/dealership'
import {localise} from '../../services/LocalizationServices'
import BulkUploadView from '../common/BulkUploadView'

const BulkUpload = () => {
  return (
    <BulkUploadView
      heading={localise('headings.uploadingRetailers')}
      errorFetch={fetchDealershipsUploadErrors}
      importFetch={fetchDealershipsUpload}
    />
  )
}

export default BulkUpload