import React, {useState} from 'react'
import {array, bool, exact, func, node, number, object, oneOfType, string} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import {localise} from '../../services/LocalizationServices'
import {v4} from 'uuid'
import {Table as BSTable} from 'react-bootstrap'
import SearchInput from '../inputs/SearchInput'
import Pagination from '../pagination/Pagination'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import {LIGHT_GREY} from '../../constants/colours'
import {LIMIT} from '../../constants/api'

const propTypes = {
  className: string,
  count: number,
  customNoData: node,
  data: exact({
    tableHeadings: array.isRequired,
    tableBody: array.isRequired,
  }).isRequired,
  footer: node,
  handleSearch: func,
  header: node,
  hideCount: bool,
  hideHeader: bool,
  loading: bool,
  pagination: exact({
    displayStatus: bool,
    className: string,
    onPageChange: func,
    pageCount: number,
    total: number,
    page: number,
    limit: number,
    offset: number,
  }),
  searchEnabled: bool,
  skeletonProps: oneOfType([func, object]),
  title: string,
}

const defaultProps = {
  className: '',
  count: null,
  customNoData: null,
  data: {
    tableHeadings: [],
    tableBody: [],
  },
  footer: null,
  handleSearch: value => Console.dev(value),
  header: null,
  hideCount: false,
  hideHeader: false,
  loading: false,
  pagination: null,
  searchEnabled: true,
  skeletonProps: {},
  title: '',
}

const Tr = styled.tr`
  transition: background-color .2s;
  &.clickable:hover {
    cursor: pointer;
    background-color: ${LIGHT_GREY};
  }
`

const Table = props => {
  const {
    className,
    count,
    customNoData,
    data,
    footer,
    handleSearch,
    header,
    hideCount,
    hideHeader,
    pagination,
    loading,
    searchEnabled,
    skeletonProps,
    title,
  } = props

  const [defaultSearchValue, setDefaultSearchValue] = useState('')
  const filtersBreakpoint = 'sm'

  const noData = (
    <div className='p-3'>
      {
        customNoData ||
        <div className='font-light-grey'>{localise('tables.noDataAvailable')}</div>
      }
    </div>
  )

  const renderHeading = (heading, i) => {
    const text = typeof heading === 'string' ? heading : heading?.text
    const attributes = typeof heading === 'string' ? {} : heading?.attributes
    return (
      <th
        className={`
        font-13 
        font-bold 
        text-capitalize 
        text-nowrap 
        py-2
        pl-2
        border-bottom
        ${attributes?.className}
        ${i < data.tableHeadings.length - 1 ? 'border-right' : ''}`}
        key={v4()}>
        {text}
      </th>
    )
  }

  const renderRow = row => row.map(({attributes, cell}, i) => (
    <td
      {...attributes}
      className={`
      font-13 
      border-bottom  
      py-2 
      pl-2
      ${i < data.tableHeadings.length - 1 ? 'border-right' : ''}
      ${attributes?.className}`}
      key={v4()}>
      {cell}
    </td>
  ))

  const renderFooter = () => {
    const pageCount = pagination?.pageCount || pagination?.total / LIMIT

    return footer || !pagination
      ? null
      : (
        <Pagination
          className={`font-13 mr-2 mb-0 ml-auto my-2 ${pagination?.className}`}
          displayStatus={pagination?.displayStatus}
          limit={pagination?.limit}
          offset={pagination?.offset}
          onPageChange={pagination?.onPageChange}
          page={pagination?.page || null}
          pageCount={pageCount}
          total={pagination?.total}
        />
      )
  }

  const Loading = ({count}) => <div className='p-2'><Skeleton count={count || 5} height={21} className='my-2' /></div>

  return (
    <div className={`table-container border ${className}`}>
      {
        header ||
        <div className={`py-2 pr-2 border-bottom ${hideHeader ? 'd-none' : `d-${filtersBreakpoint}-flex`}`}>
          <div className={`row-count pl-2 flex-grow-1 font-17 text-nowrap mb-2 mb-${filtersBreakpoint}-0`}>
            <span className='mr-2 font-20 font-bold' hidden={hideCount}>{count || '00000'}</span>
            <span className='title mr-2 text-truncate font-15'>{title}</span>
          </div>
          {
            searchEnabled && (
              <SearchInput
                defaultValue={defaultSearchValue}
                handleSearch={value => {setDefaultSearchValue(value); handleSearch(value)}}
              />
            )
          }
        </div>
      }
      {
        loading
          ? <Loading count={skeletonProps.count} />
          : data?.tableBody?.length > 0
            ? (
              <>
                <BSTable className='mb-0' size='sm' responsive borderless>
                  <thead>
                    <tr>
                      {
                        data?.tableHeadings?.map(renderHeading)
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data?.tableBody?.map(({row, rowAttributes}) => (
                        <Tr {...rowAttributes} key={v4()}>{renderRow(row)}</Tr>)
                      )
                    }
                  </tbody>
                </BSTable>
              </>
            )
            : noData
      }
      {data?.tableBody?.length > 0 && renderFooter()}
    </div>
  )
}

Table.propTypes = propTypes
Table.defaultProps = defaultProps

export default Table
