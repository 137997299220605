import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import {BULK_UPLOAD_URL, DEALERSHIP_PRODUCT_URL, EDIT_URL, NEW_URL, VARIANTS_URL} from '../constants/urls'
import BulkUpload from '../views/product/BulkUpload'
import Product from '../views/product/Product'
import Products from '../views/product/Products'
import ProductCreate from '../views/product/ProductCreate'
import ProductEdit from '../views/product/ProductEdit'
import ProductVariant from '../views/product/ProductVariant'
import DealershipProduct from '../views/product/DealershipProduct'

const ProductRoutes = ({path}) =>
  <Switch>
    <RouteWithLayout exact path={path} component={Products} />
    <RouteWithLayout exact path={`${path}${NEW_URL}`} component={ProductCreate} />
    <RouteWithLayout exact path={`${path}${BULK_UPLOAD_URL}/:slug`} component={BulkUpload} />
    <RouteWithLayout exact path={`${path}${DEALERSHIP_PRODUCT_URL}/:slug`} component={DealershipProduct} />
    <RouteWithLayout exact path={`${path}/:slug`} component={Product} />
    <RouteWithLayout exact path={`${path}/:slug${EDIT_URL}`} component={ProductEdit} />
    <RouteWithLayout exact path={`${path}/:productSlug${VARIANTS_URL}/:slug`} component={ProductVariant} />
  </Switch>

export default ProductRoutes
