import {apiClient} from '../client'
import {clearAuth, getToken} from '../../utilities/auth-helpers/authHelpers'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchCurrentUser = async () => {
  if (getToken()) {
    const {admin} = await apiClient.get('admins/me').catch(handleError)
    return admin
  } else {
    clearAuth()
    return null
  }
}