import React, {useEffect, useState} from 'react'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {fetchDealershipProducts} from '../../api/product/product'
import {useHistory, useParams} from 'react-router-dom'
import {localise} from '../../services/LocalizationServices'
import Skeleton from 'react-loading-skeleton'
import {Button, Card as BSCard} from 'react-bootstrap'
import Pagination, {calculateOffset} from '../../components/pagination/Pagination'
import {LIMIT} from '../../constants/api'
import {useSelector} from 'react-redux'
import {isLoading} from '../../store/selectors/loadingSelectors'
import {v4} from 'uuid'
import {PRODUCTS_URL, VARIANTS_URL} from '../../constants/urls'

const ProductVariants = () => {
  const {slug} = useParams()
  const {push} = useHistory()

  const [dealershipProducts, setDealershipProducts] = useState([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)

  const loading = useSelector(isLoading)

  useEffect(() => {
    toggleLoading()
    fetchDealershipProducts(slug, {offset})
      .then(({dealership_products, total}) => {
        setDealershipProducts(dealership_products)
        setTotal(total)
      })
      .finally(toggleLoading)
  }, [slug, offset])

  const toProductVariant = variantId => push(`${PRODUCTS_URL}/${slug}${VARIANTS_URL}/${variantId}`)

  return (
    <BSCard>
      <div className='font-bold font-20 py-3 pl-2 border-bottom'>
        {localise('headings.retailerVariants')}
      </div>
      {
        dealershipProducts?.map(({id, dealership}) => (
          loading ? <Skeleton height={50} width='96%' className='mx-3' key={v4()}/> :
            <div className='d-flex p-2 border-bottom' key={v4()}>
              <div className='mr-2 flex-grow-1 font-bold font-20 text-truncate'>
                {dealership}
              </div>
              <div>
                <Button
                  className='font-13 px-2 py-1 font-dark rounded-0'
                  onClick={() => toProductVariant(id)}
                  variant='outline-grey'>
                  {localise('buttonText.view')}
                </Button>
              </div>
            </div>
        ))
      }
      <Pagination
        className='mt-2 ml-auto pr-2'
        data={dealershipProducts}
        onPageChange={page => setOffset(calculateOffset(page, LIMIT))}
        offset={offset}
        page={offset + 1}
        pageCount={total / LIMIT}
        total={total}
      />
    </BSCard>
  )
}

export default ProductVariants