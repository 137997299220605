import React, {useEffect, useState} from 'react'
import OrdersTable from '../../../components/tables/OrdersTable'
import {useParams} from 'react-router-dom'
import {fetchDealershipOrders} from '../../../api/dealership/dealership'
import {calculateOffset} from '../../../components/pagination/Pagination'
import {LIMIT} from '../../../constants/api'

const Orders = () => {
  const {slug} = useParams()

  const [orders, setOrders] = useState([])
  const [total, setTotal] = useState()
  const [offset, setOffset] = useState(0)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchDealershipOrders(slug,{offset, query})
      .then(({orders, total}) => {
        setTotal(total)
        setOrders(orders.map(order => ({
          ...order,
          status: order.display_status,
          orderCode: order.order_code,
          orderValue: order.order_value?.display,
          date: order.created_at,
        })))
      })
      .finally(() => setLoading(false))
  }, [slug, offset, query])

  return (
    <OrdersTable
      hideDealership
      count={total}
      clickable={false}
      data={orders}
      handleSearch={setQuery}
      loading={loading}
      pagination={{
        onPageChange: page => setOffset(calculateOffset(page, LIMIT)),
        total,
        offset,
      }}
    />
  )
}

export default Orders
