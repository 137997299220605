import React, {useMemo, useState} from 'react'
import Card from '../../components/cards/Card'
import NUMBER_ICON from '../../assets/svgs/number-icon.svg'
import VALUE_ICON from '../../assets/svgs/value-icon.svg'
import StatCard from '../../components/cards/StatCard'
import {Col, Row} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import ColumnChart from '../../components/charts/ColumnChart'
import {fetchOrderNumber, fetchOrderValues} from '../../api/dashboard/dashboard'
import TabMenu from '../../components/navs/TabMenu'
import DropdownButton from '../../components/buttons/DropdownButton'
import {fetchDealerships} from '../../api/dealership/dealership'
import {v4} from 'uuid'
import DropdownItem from 'react-bootstrap/DropdownItem'
import Skeleton from 'react-loading-skeleton'

const PERIOD = {
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
}

const Dashboard = () => {
  const [activeDealership, setActiveDealership] = useState(null)
  const [dealerships, setDealerships] = useState([])
  const [numberChartData, setNumberChartData] = useState([])
  const [valueData, setValueData] = useState([])
  const [numberPeriod, setNumberPeriod] = useState(PERIOD.WEEK)
  const [valuePeriod, setValuePeriod] = useState(PERIOD.WEEK)
  const [numberTotals, setNumberTotals] = useState({})
  const [valueTotals, setValueTotals] = useState({})
  const [loading, setLoading] = useState(true)

  useMemo(() => {
    fetchDealerships().then(({dealerships}) => {
      setDealerships(dealerships)
      if (dealerships?.length > 0) setActiveDealership(dealerships[0])
    })
  }, [])

  useMemo(() => {
    setLoading(true)
    fetchOrderNumber({period: numberPeriod, dealership_id: activeDealership?.id})
      .then(({chart_data, total}) => {
        setNumberChartData(parseChartData(chart_data))
        setNumberTotals(parseTotalsData(chart_data, total))
      })
      .finally(() => setLoading(false))
  }, [activeDealership?.id, numberPeriod])

  useMemo(() => {
    setLoading(true)
    fetchOrderValues({period: valuePeriod, dealership_id: activeDealership?.id})
      .then(({chart_data, total}) => {
        setValueData(parseChartData(chart_data))
        setValueTotals(parseTotalsData(chart_data, total))
      })
      .finally(() => setLoading(false))
  }, [activeDealership?.id, valuePeriod])

  const chartData = [
    {
      data: numberChartData,
      heading: localise('headings.numberOfOrders'),
      tabHandler: setNumberPeriod,
    },
    {
      data: valueData,
      heading: localise('headings.valueOfOrders'),
      tabHandler: setValuePeriod,
    },
  ]

  const statCardData = [
    {
      heading: localise('headings.totalNumberOfOrders'),
      icon: {src: NUMBER_ICON},
      value: numberTotals?.grandTotal,
    },
    {
      heading: localise('headings.totalValueOfOrders'),
      icon: {src: VALUE_ICON},
      value: valueTotals?.grandTotal,
    },
  ]

  const parseChartData = chartData => {
    const guestNumbersData = {}
    const bentleyIdNumbersData = {}
    const bentleyId = chartData?.filter(({slug}) => slug === 'bentley_id')[0]
    const guest = chartData?.filter(({slug}) => slug === 'guest')[0]

    bentleyId?.data?.map(([{key, value}]) => Object.assign(bentleyIdNumbersData, {[key]: value}))
    guest?.data?.map(([{key, value}]) => Object.assign(guestNumbersData, {[key]: value}))

    return ([
      {
        name: bentleyId?.name,
        data: bentleyIdNumbersData,
        total: bentleyId?.total,
      },
      {
        name: guest?.name,
        data: guestNumbersData,
        total: guest?.total,
      },
    ])
  }

  const parseTotalsData = (chartData, total = 0) => {
    return {
      bentleyIdTotal: chartData?.filter(({slug}) => slug === 'bentley_id')[0]?.total,
      grandTotal: total,
      guestTotal: chartData?.filter(({slug}) => slug === 'guest')[0]?.total,
    }
  }

  const Loading = () => {
    return (
      <>
        <Col className='my-2' md='6'><Skeleton height={60} /></Col>
        <Col className='my-2' md='6'><Skeleton height={60} /></Col>
        <Col className='my-2' xs='12'><Skeleton height={400} /></Col>
        <Col className='my-2' xs='12'><Skeleton height={400} /></Col>
      </>
    )
  }

  const StatCards = () => statCardData?.map(({heading, icon, value}, index) => (
    <Col md={6} key={v4()}>
      <StatCard
        className={`d-flex ${index !== statCardData.length - 1 ? 'mb-4 mb-md-0' : ''}`}
        heading=''
        headingClasses='mb-0'
        icon={icon}>
        <>
          <div className='font-bold font-17'>
            {heading}
            <span className='font-regular ml-2'>{value}</span>
          </div>
          <div>
            {
              numberChartData?.map(({name, total}) =>
                <div className='font-grey font-bold font-15' key={v4()}>
                  {name}
                  <span className='font-regular ml-2'>
                    {total}
                  </span>
                </div>
              )
            }
          </div>
        </>
      </StatCard>
    </Col>
  ))

  const Charts = () => chartData?.map(({data, heading, tabHandler}) =>
    <Col xs={12} className='mt-4' key={v4()}>
      <Card className='pt-2 px-3'>
        <div className='d-flex my-auto justify-content-between'>
          <div className='font-bold font-17 p-3'>
            {heading}
          </div>
          <div>
            {
              <TabMenu
                activeKey={data === numberChartData ? numberPeriod : valuePeriod}
                className='chart-tab font-13'
                data={[
                  {
                    eventKey: PERIOD.WEEK,
                    tabClassName: 'pr-0 pb-0',
                    title: localise('headings.weeks'),
                  },
                  {
                    eventKey: PERIOD.MONTH,
                    tabClassName: 'pr-0 pb-0',
                    title: localise('headings.months'),
                  },
                  {
                    eventKey: PERIOD.YEAR,
                    tabClassName: 'pr-0 pb-0',
                    title: localise('headings.years'),
                  },
                ]}
                onSelect={tabHandler}
              />
            }
          </div>
        </div>
        <ColumnChart data={data} />
      </Card>
    </Col>
  )

  return (
    <Row className='mb-5 mw-100'>
      <Col xs={12}>
        <div className='d-flex justify-content-between'>
          <div className='font-28 font-bold mb-3 flex-grow-1'>
            {localise('nav.dashboard')}
          </div>
          <div className='my-auto text-nowrap'>
            <DropdownButton
              className='input-sm border-black'
              menu={dealerships?.map(dealership => (
                <DropdownItem
                  className='dropdown-item cursor-pointer'
                  onClick={() => setActiveDealership(dealership)}
                  key={v4()}>
                  {dealership?.name}
                </DropdownItem>
              ))}
              toggleClasses='position-relative bottom-8'>
              {activeDealership?.name || localise('headings.dealership')}
            </DropdownButton>
          </div>
        </div>
      </Col>
      {
        loading
          ? <Loading />
          : <>
            <StatCards />
            <Charts />
          </>
      }
    </Row>
  )
}

export default Dashboard
