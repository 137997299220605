import React from 'react'
import {string} from 'prop-types'
import {v4} from 'uuid'
import {localise} from '../../services/LocalizationServices'
import useNav from '../../hooks/useNav'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {BRAND_PRIMARY} from '../../constants/colours'
import {ROOT_URL} from '../../constants/urls'
import {Image, Navbar} from 'react-bootstrap'
import {COMPANY_LOGO_SMALL} from '../../constants/pngs'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const SideNavContainer = styled.nav`
  z-index: 1;
  min-height: 100vh;
  ul {
    list-style: none;
    padding: 0;
    li {
      a {
        div {
          transition: background-color .3s ease-out, color .1s ease-out;
          &.active,
          &:hover
          {
            background-color: ${BRAND_PRIMARY};
            color: white;
            font-family: BentleyBold, sans-serif;
          }
        }
      }
    }
  }
`

const Brand = styled(Navbar.Brand)`
  height: 84px;
  min-width: 225px;
`

const SideNav = ({className}) => {
  const {links, isActiveLink} = useNav()
  const navList = [
    links.dashboard,
    links.dealerships,
    links.products,
    links.orders,
    links.customers,
  ]

  const renderNavList = () => navList.map(({activeViews, to, translationKey}) => {
    return (
      <li key={v4()}>
        <Link to={to} className='text-decoration-none'>
          <div className={`pl-4 pr-2 py-3 ${isActiveLink(activeViews || []) ? 'active' : ''}`}>
            {localise(translationKey)}
          </div>
        </Link>
      </li>
    )
  })

  return (
    <SideNavContainer className={`side-nav-container bg-white ${className}`}>
      <Brand className='p-3 text-center' href={ROOT_URL}>
        <Image src={COMPANY_LOGO_SMALL} alt={localise('alt.companyLogo')} />
      </Brand>
      <div className='border-bottom-primary-green'/>
      <ul>
        {renderNavList()}
      </ul>
    </SideNavContainer>
  )
}

SideNav.propTypes = propTypes
SideNav.defaultProps = defaultProps

export default SideNav
