import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

export const fetchDealerships = async opts => {
  const params = getIndexParams(opts)

  const {dealerships, meta: {total}} = await apiClient.get('dealerships', {params}).catch(handleError)
  return {dealerships, total}
}

export const fetchDealershipProducts = async (id, opts) => {
  const params = getIndexParams(opts)

  const {products, meta: {total}} = await apiClient.get(`dealerships/${id}/products`, {params}).catch(handleError)
  return {products, total}
}

export const fetchDealershipOrders = async (id, opts) => {
  const params = getIndexParams(opts)

  const {orders, meta: {total}} = await apiClient.get(`dealerships/${id}/orders`, {params}).catch(handleError)
  return {orders, total}
}

export const fetchDealershipCustomers = async (id, opts) => {
  const params = getIndexParams(opts)

  const {customers, meta: {total}} = await apiClient.get(`dealerships/${id}/customers`, {params}).catch(handleError)
  return {customers, total}
}

export const fetchDealershipAdmins = async (id, opts) => {
  const params = getIndexParams(opts)

  const {admins, meta: {total}} = await apiClient.get(`dealerships/${id}/admins`, {params}).catch(handleError)
  return {admins, total}
}

export const deleteDealership = async id => {
  const {deleted} = await apiClient.delete(`dealerships/${id}`).catch(handleError)
  return deleted
}

const dealershipAction = async (action, url, data) => {
  const {dealership} = await apiClient[action](url, data).catch(handleError)
  return dealership
}

export const fetchDealership = id => dealershipAction('get', `dealerships/${id}`)
export const createDealership = dealership => dealershipAction('post', 'dealerships', {dealership})
export const updateDealership = (id, dealership) => dealershipAction('put', `dealerships/${id}`, {dealership})

export const bulkUploadDealerships = async url => {
  const {import: upload} = await apiClient.post('dealerships/imports', {
    import: {
      csv_file_url: url,
    },
  }).catch(handleError)
  return upload
}

export const fetchDealershipsUpload = async id => {
  const {import: upload} = await apiClient.get(`dealerships/imports/${id}`).catch(handleError)
  return upload
}

export const fetchDealershipsUploadErrors = async id => {
  const {errors, meta: {total}} = await apiClient.get(`dealerships/imports/${id}/errors`).catch(handleError)
  return {errors, total}
}