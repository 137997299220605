import {localise} from '../services/LocalizationServices'

const useOrders = () => {
  const statuses = [
    {
      label: localise('nav.new'),
      param: 'pending',
    },
    {
      label: localise('nav.contactedCustomer'),
      param: 'contacted_customer',
    },
    {
      label: localise('nav.fittingBooked'),
      param: 'fitting_booked',
    },
    {
      label: localise('nav.complete'),
      param: 'completed',
    },
    {
      label: localise('nav.cancelled'),
      param: 'rejected',
    },
    {
      label: localise('nav.refunded'),
      param: 'returned',
    },
  ]
  return {statuses}
}

export default useOrders
