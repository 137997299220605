import React, {useEffect, useState} from 'react'
import {PRODUCTS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {createProduct} from '../../api/product/product'
import ProductForm from './ProductForm'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-hot-toast'
import Prompt from '../../components/modals/Prompt'
import {clearProduct} from '../../store/slices/productSlice'
import {useDispatch} from 'react-redux'

const ProductCreate = () => {
  const dispatch = useDispatch()

  const [dirty, setDirty] = useState(false)
  const {goBack, push} = useHistory()

  useEffect(() => {
    dispatch(clearProduct())
  }, [dispatch])

  const toProduct = id => push(`${PRODUCTS_URL}/${id}`)

  const create = values => {
    toggleLoading()
    createProduct({
      description: values?.description,
      images: values?.images,
      name: values?.name,
      overview: values?.overview,
      product_information: values?.productInformation,
      video: values?.video,
    })
      .then(({id}) => {
        setDirty(false)
        toast.success(localise('toast.productCreateSuccess'))
        toProduct(id)
      })
      .finally(toggleLoading)
  }

  return (
    <>
      <Prompt when={dirty} />
      <ProductForm
        breadcrumbs={[
          {
            active: false,
            href: PRODUCTS_URL,
            text: localise('nav.products'),
          },
          {
            active: true,
            text: localise('headings.create'),
          },
        ]}
        onSubmit={create}
        onCancel={() => goBack()}
        isDirty={setDirty}
      />
    </>
  )
}

export default ProductCreate
