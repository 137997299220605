import React from 'react'
import {PRODUCTS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {useSelector} from 'react-redux'
import {
  getProductDealershipName,
} from '../../store/selectors/productSelectors'
import ProductView from '../common/ProductView'

const DealershipProduct = () => {
  const productDealershipName = useSelector(getProductDealershipName)

  return (
    <ProductView
      breadcrumbs={[
        {
          active: false,
          href: PRODUCTS_URL,
          text: localise('nav.products'),
        },
        {
          active: true,
          text: productDealershipName,
        },
      ]}
    />
  )
}

export default DealershipProduct
