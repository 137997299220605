import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {
  AUTH_URL,
  CODE_URL,
  CUSTOMERS_URL,
  DASHBOARD_URL,
  DEALERSHIPS_URL,
  ORDERS_URL,
  PRODUCTS_URL,
  ROOT_URL,
} from '../constants/urls'
import RouteWithLayout from './RouteWithLayout'
import Dashboard from '../views/dashboard/Dashboard'
import DealershipRoutes from './DealershipRoutes'
import ProductRoutes from './ProductRoutes'
import OrderRoutes from './OrdersRoutes'
import CustomerRoutes from './CustomerRoutes'
import Auth from '../views/auth/Auth'
import AuthCode from '../views/auth/AuthCode'

const AppRoute = () => {
  return (
    <Switch>
      <Route exact path={ROOT_URL}><Auth /></Route>
      <Route exact path={`${AUTH_URL}${CODE_URL}`} component={AuthCode} />
      <RouteWithLayout exact path={DASHBOARD_URL} component={Dashboard} />
      <DealershipRoutes path={DEALERSHIPS_URL} />
      <ProductRoutes path={PRODUCTS_URL} />
      <OrderRoutes path={ORDERS_URL} />
      <CustomerRoutes path={CUSTOMERS_URL} />
    </Switch>
  )
}

export default AppRoute
