import {combineReducers} from 'redux'
import {loadingIndicatorReducer} from './loadingIndicatorSlice'
import {dealershipReducer} from './dealershipSlice'
import {CLEAR_STATE} from '../../constants/reduxTypes'
import {productReducer} from './productSlice'
import {currentUserReducer} from './currentUserSlice'
import {customerReducer} from './customerSlice'
import {configurationReducer} from './configurationSlice'
import {orderReducer} from './orderSlice'

const appReducer = combineReducers({
  configuration: configurationReducer,
  currentUser: currentUserReducer,
  customer: customerReducer,
  dealership: dealershipReducer,
  loading: loadingIndicatorReducer,
  order: orderReducer,
  product: productReducer,
})

export default (state, action) => {
  if (action.type === CLEAR_STATE) {
    state = undefined
  }

  return appReducer(state, action)
}
